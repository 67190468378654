import { ReactElement } from 'react';
import { PAGE_URL } from '../constants';
import { CabButton } from '@CabComponents';
import { useNavigate } from 'react-router';
import CabinetPage from '../components/Common/CabinetPage';
import { Box, Typography } from '@mui/material';

export const PageNotFound = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <CabinetPage
      pageName={'PageNotFound'}
      noHeader
    >
      <Box height='100%' width='100%' display='flex' justifyContent='center' alignItems='center'>
        <Box display='grid' gap={1}>
          <Typography>Page Not Found</Typography>
          <CabButton onClick={(): void => navigate(PAGE_URL.DASHBOARD)}>Home</CabButton>
        </Box>
      </Box>
    </CabinetPage>
  );
};

export default PageNotFound;